<template>
  <div>
    <div>
      <b-card>
        <div class="w-100 text-uppercase">
          <h4 class="mb-3" v-if="!isEdit"> Add New Event </h4>
          <h5 class="mb-3" v-if="isEdit">Edit Event</h5>
        </div>
        <form @submit.prevent="handleSubmit">
          <b-form-group class="mb-5">
            <!-- Title & Sub_Title -->
            <div class="mb-3">
              <b-row>
                <b-col>
                  <div>
                    <label for="title" class="font-weight-bolder">Title :</label>
                    <b-form-input v-model="eventPostForm.title" id="title" placeholder="Enter Event Title" required>
                    </b-form-input>
                  </div>
                </b-col>

                <b-col>
                  <div>
                    <label for="subTitle" class="font-weight-bolder">Sub Title :</label>
                    <b-form-input v-model="eventPostForm.sub_title" id="subTitle" placeholder="Enter Event Sub Title"
                      required>
                    </b-form-input>
                  </div>
                </b-col>
              </b-row>
            </div>

            <!-- Categories -->

            <!-- Description -->
            <div class="mb-3">
              <label for="des" class="font-weight-bolder">Description :</label>
              <vue-editor v-model="eventPostForm.description"></vue-editor>
            </div>

            <!-- Contact Info -->
            <div class="mb-3">
              <label for="contact-info" class="font-weight-bolder">Contact Info :</label>
              <b-form-input v-model="eventPostForm.contact_info" id="contact-info" placeholder="Enter Contact Info"
                required>
              </b-form-input>
            </div>

            <!-- Email & Phone -->
            <b-row class="mb-3">
              <b-col>
                <div>
                  <label for="email" class="font-weight-bolder">Email :</label>
                  <b-form-input v-model="eventPostForm.email" id="email" type="email" placeholder="example@gmail.com"
                    required>
                  </b-form-input>
                </div>
              </b-col>

              <b-col>
                <div>
                  <label for="phno" class="font-weight-bolder">Phone :</label>
                  <b-form-input v-model="eventPostForm.phone" id="phno" placeholder="Phone Number" required>
                  </b-form-input>
                </div>
              </b-col>
            </b-row>

            <!-- Latitude & Longitude -->
            <div class="mb-3">
              <b-row>
                <b-col>
                  <div>
                    <label for="lat" class="font-weight-bolder">Latitude :</label>
                    <b-form-input v-model="eventPostForm.lat" id="lat" placeholder="example - 16.768148" required>
                    </b-form-input>
                  </div>
                </b-col>

                <b-col>
                  <div>
                    <label for="lng" class="font-weight-bolder">Longitude :</label>
                    <b-form-input v-model="eventPostForm.lng" id="lng" placeholder="example - 96.174930" required>
                    </b-form-input>
                  </div>
                </b-col>
              </b-row>
            </div>

            <!-- Booking_status & Publish_status -->
            <b-row class="mb-3">
              <b-col>
                <div>
                  <label for="status" class="font-weight-bolder">Ticket Mode :</label>
                  <b-form-checkbox v-model="checked" @change="toggle(checked)" name="check-button" switch>
                  </b-form-checkbox>
                </div>
              </b-col>

              <b-col>
                <div>
                  <label for="status" class="font-weight-bolder">Publish :</label>
                  <b-form-checkbox v-model="publish" @change="toggle(publish)" name="check-publish-button" switch>
                  </b-form-checkbox>
                </div>
              </b-col>
            </b-row>

            <!-- Opening_Date & Closing_Date -->
            <b-row>
              <b-col>
                <div>
                  <label for="e_start" class="font-weight-bolder">Event Start Date :</label>
                  <b-form-datepicker id="e_start" v-model="eventPostForm.opening_date" class="mb-2"
                    required></b-form-datepicker>
                </div>
              </b-col>

              <b-col>
                <div>
                  <label for="e_end" class="font-weight-bolder">Event End Date :</label>
                  <b-form-datepicker id="e_end" v-model="eventPostForm.closing_date" class="mb-2"
                    required></b-form-datepicker>
                </div>
              </b-col>
            </b-row>

            <!-- Photos -->
            <div v-if="!isEdit" class="mb-4">
              <label for="contact-info" class="font-weight-bolder">Photos :</label>
              <div>
                <MyPhotoUpload @getPhotoId="uploadPhoto" />
              </div>
            </div>
          </b-form-group>

          <template v-if="!isEdit">
            {{ allUploaded() }}
          </template>

          <div class="d-flex flex-column flex-md-row">
            <div class="w-100 text-center mb-2 mb-md-0">
              <b-button type="button" variant="outline-primary" @click="() => router.go(-1)" class="col-md-10 col-lg-8">
                <!-- <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner> -->
                <span>Back</span>
              </b-button>
            </div>

            <div class="w-100 text-center" v-if="!isEdit">
              <b-button variant="primary" class="col-md-10 col-lg-8" type="submit">
                <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
                <span v-else>Submit</span>
              </b-button>
            </div>

            <div class="w-100 text-center" v-if="isEdit">
              <b-button type="button" variant="primary" @click="updateData(eventPostForm.id)" class="col-md-10 col-lg-8">
                <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
                <span v-else>Update</span>
              </b-button>
            </div>
          </div>
        </form>
      </b-card>
    </div>
  </div>
</template>
  
<script>
import store from "@/store";
// import MapLocationSelector from 'vue-latlng-picker';
import Table from "../components/Table.vue";
import { VueEditor } from "vue2-quill-editor";
import MyPhotoUpload from "../components/MyPhotoUpload.vue";
import router from "@/router";
import { useRouter } from "@core/utils/utils";
import {
  ref,
  reactive,
  computed,
  watch,
  // ref, watch, computed, onUnmounted,
} from "@vue/composition-api";
import {
  BCard,
  BButton,
  BSpinner,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BModal,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormTimepicker,
  BFormDatepicker,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BModal,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormTimepicker,
    BFormDatepicker,
    // MapLocationSelector,
    VueEditor,
    Table,
    MyPhotoUpload,
  },
  setup(props, { emit }) {
    const { router } = useRouter();
    const routerQuery = router.app._route.query;
    const spinner = ref(false);
    const checked = ref(true);
    const publish = ref(true);
    const isAllUploaded = ref(false);
    const categories = ref(JSON.parse(routerQuery.categories));
    const isEdit = ref(JSON.parse(routerQuery.isEdit));
    const mulImgs = ref([]);
    const eventPostForm = ref({
      id: "",
      title: "",
      category_id: 0,
      sub_title: "",
      description: "",
      contact_info: "",
      email: "",
      phone: "",
      lat: "",
      lng: "",
      ticket_mode: 1,
      status: "active",
      photos: [117,118,119],
      opening_date: "",
      closing_date: "",
    });

    //Datas Fetching
    //Functions
    const toggle = (c = checked.value, p = publish.value) => {
      c ? (eventPostForm.ticket_mode = 1) : (eventPostForm.ticket_mode = 0);
      p
        ? (eventPostForm.status = "active")
        : (eventPostForm.status = "inactive");
    };
    const uploadPhoto = (photoIds) => {
      mulImgs.value = [...mulImgs.value, photoIds];
      eventPostForm.photos = mulImgs.value;
    };
    // Clear Datas
    const clearDatas = () => {
      eventPostForm.title = "";
      eventPostForm.category_id = 0;
      eventPostForm.sub_title = "";
      eventPostForm.description = "";
      eventPostForm.contact_info = "";
      eventPostForm.phone = "";
      eventPostForm.email = "";
      eventPostForm.lat = "";
      eventPostForm.lng = "";
      eventPostForm.ticket_mode = 1;
      eventPostForm.opening_date = "";
      eventPostForm.closing_date = "";
    };

    //Data Posting
    const handleSubmit = () => {
      console.log(eventPostForm);
      //spinner.value = true;
      store
      .dispatch("app-mypages/postDatas", {
          path: "events",
          payload: eventPostForm,
        })
        .then((response) => {
          spinner.value = false;
          isEdit.value = false;
          clearDatas();
          router.go(-1);
        });
    };

    // Data Editing
    if (isEdit.value) {
      const editEventData = () => {
        console.log('asdf');
        store.dispatch("app-mypages/fetchDetails", {
          path: "events",
          id: routerQuery.editDatas,
        })
        .then((response) => {
          console.log(response.data.data)
          eventPostForm.value = response.data.data;
          console.log(eventPostForm.value);
          spinner.value = false;
        });
        // let editDatas = JSON.parse(routerQuery.editDatas);
        // eventPostForm.id = editDatas.id;
        // eventPostForm.title = editDatas.title;
        // eventPostForm.category_id = 0;
        // eventPostForm.sub_title = editDatas.sub_title;
        // eventPostForm.description = editDatas.description;
        // eventPostForm.contact_info = editDatas.contact_info;
        // eventPostForm.phone = editDatas.phone;
        // eventPostForm.email = editDatas.email;
        // eventPostForm.lat = editDatas.lat;
        // eventPostForm.lng = editDatas.lng;
        // eventPostForm.ticket_mode = editDatas.ticket_mode;
        // eventPostForm.opening_date = editDatas.opening_date;
        // eventPostForm.closing_date = editDatas.closing_date;
      };
      editEventData();
    }
    const updateData = () => {
      spinner.value = true;
      store
        .dispatch("app-mypages/updateData", {
          path: "events",
          payload: eventPostForm,
        })
        .then((response) => {
          spinner.value = false;
          isEdit.value = false;
          clearDatas();
          router.go(-1);
        });
    };

    // Close Function
    const close = () => {
      isEdit.value = false;
      clearDatas();
    };

     // All Uploaded
     const allUploaded = () => {
      if (eventPostForm.title &&
        eventPostForm.sub_title &&
        eventPostForm.description &&
        eventPostForm.contact_info &&
        eventPostForm.email &&
        eventPostForm.phone &&
        eventPostForm.lat &&
        eventPostForm.lng &&
        eventPostForm.opening_date &&
        eventPostForm.closing_date &&
        eventPostForm.photos.length !== 0) {
        isAllUploaded.value = true;
      }
    }

    return {
      spinner,
      isEdit,
      checked,
      publish,
      categories,
      handleSubmit,
      toggle,
      uploadPhoto,
      eventPostForm,
      updateData,
      close,
      router,
      // locationUpdated,
      isAllUploaded,
      allUploaded,
    };
  },
};
</script>